import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { NavigationListQuery } from "./__generated__/NavigationListQuery"


type NavigationListProps = { name?: string, className?: string, liClassName?: string, current?: string, withThemeSwitch?: boolean, currentTheme?: number, switchTheme?: () => void, themes?: Theme[] }
const List: React.FC<NavigationListProps> = ({
    name,
    className = "",
    liClassName = "",
    current,
    withThemeSwitch = true,
    currentTheme,
    switchTheme,
    themes,
}) => {
    const data = useStaticQuery<NavigationListQuery>(graphql`
        query NavigationListQuery {
            site {
                siteMetadata {
                    navLinks {
                        name
                        url
                    }
                }
            }
        }
    `)
    const items = data.site.siteMetadata.navLinks
    const list = items.map((e, i) => (
        <ListItem
            key={`navigation-${name}-${i}`}
            data={e}
            active={`/${current}` === e.url}
            liClassName={liClassName}
        />
    ))

  

    return <ul className={className}>{list}</ul>
}

const ListItem = ({ data, active, liClassName }) => {
    return (
        <li className={`${liClassName} ${active ? "active" : ""}`}>
            <Link to={data.url} title={data.name} className="text-color-2 focus:text-primary">
                <span>{data.name}</span>
            </Link>
        </li>
    )
}

export default List
