import { Link as LinkModal, PageProps } from "gatsby"
import React from "react"

const Footer: React.FC<PageProps> = () => {
  return (
    <footer className="fixed inset-x-0 bottom-0 bg-[#f5f5dc]">
      <div className="container mx-2 py-2 text-sm">
        <span className="back-to-top">
          
          <LinkModal to="/Impressum/" state={{ modal: true }}>
            Impressum
          </LinkModal>
          {' '}
          <LinkModal to="/Datenschutz/" state={{ modal: true }}>
            Datenschutz
          </LinkModal>
        </span>
      </div>
    </footer>
  )
}

export default Footer
